<template>
  <v-container class="view_distributeur">
      <v-btn
        class="mb-2"
        color="pink"
        to="/distributeurs"
        dark
      >
        <v-icon
          dark
          left
        >
          fa-arrow-left
        </v-icon>Liste des distributeurs
      </v-btn>
      
      <!-- Image -->
      <v-img
        :src="$root.distributeur_obj.href"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      >
      </v-img>

      <!-- Nom -->
      <h3 class="mt-5">{{ $root.distributeur_obj.nom }}</h3>
      
      <div class="mt-5">
      <!-- Description -->
      <small>{{ $root.distributeur_obj.description }}</small>
      
      <!-- Horaires -->
      <div class="text-center mt-5">
        <p>{{$root.messages.horaire}}:</p>
        <h1>{{ $root.distributeur_obj.horaires }}</h1>
      </div>

      <!-- Adresse -->
      <div class="text-center mt-5">
        <p>{{$root.messages.adresse}}:</p>
        <h3>{{ $root.distributeur_obj.adresse }}</h3>
        
        <!-- Bouton m'y rendre -->
        <v-btn block 
          class="mt-3" 
          color="success" 
          outlined 
          :disabled="$root.distributeur_obj.google_map_addr == null"
          :href="$root.distributeur_obj.google_map_addr">
          {{ $root.messages.comment_my_rendre }}
        </v-btn>
        
      </div>
      
      
      <v-card
        class="mx-auto mt-5"
        elevation="1"
      >
          
        <!-- Informations -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><b>Parking </b>: Oui</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><b>Paiement </b>: Carte bancaire uniquement</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
          
      </v-card>
      
      </div>
      
      <v-divider class="mx-4 mt-5"></v-divider>
      
      <!-- Informations sur le produit -->
      
      
      <!-- Disponibilité -->
      <v-btn block class="mt-3" color="pink" outlined @click.native="addDistributeurInFiltre($root.distributeur_obj.nom)" to="produits">
        {{ $root.messages.show_produits_distributeurs }}
      </v-btn>
      
  </v-container>
</template>

<script>

export default {
  name: 'distributeur',
  methods: {
    addDistributeurInFiltre(distributeur_obj){
      this.$root.filtres = [distributeur_obj]; 
    },
  }
}
</script>

<style>
</style>

<template>
  <div>
    
    <!-- Carte -->
    <v-card 
      v-if="show"
      style="padding: 10px; margin-top: 1rem"
      color="lighten-1"
    >

      <v-row class="justify-center" style="padding: 10px">
        <v-icon x-large fab center color="pink">
          fa fa-folder-open
        </v-icon>
      </v-row>
        
      <v-row class="justify-center text-center" style="padding: 10px">
        {{ message_name }}
      </v-row>

    </v-card>
  
  </div>
</template>

<script>
export default {
  name: 'emptyBox',
  props: {
    panel_name: String,
    message_name: String, 
    show: Boolean,
  },
}
</script>

<style>
</style>
<template>
  <v-container class="view_produits">
  
    <!-- Titre -->
    <v-row dense class="align-center space-top mb-0">
      <h3 class="light" style="padding-left: 5px">{{ $root.messages.nos_produits_titre }}</h3>      
      <v-spacer></v-spacer>
      
      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? 'fa-chevron-up' : 'fa-chevron-down' }}</v-icon>
      </v-btn>
      
    </v-row>
        
    <!-- Texte -->
    <v-expand-transition>
      <div v-show="show">
        
        <v-divider></v-divider>

        <v-card-text>
          {{ $root.messages.nos_produits_text }}
        </v-card-text>
      </div>
    </v-expand-transition>
    
    <v-container v-if="$root.isEmpty(produits)">
      <!-- Empty -->
      <emptyBox message_name="Il n'y a pas de produits !" :show="$root.isEmpty(produits)"></emptyBox>
    </v-container>
    
    <v-container v-if="!$root.isEmpty(produits)">
    <v-row 
      v-for="categorie in categories" 
      :key="categorie.id"
    >
      <template v-if="categorieHasProduits(categorie.id)">
  
        <v-container>
          <v-row>
            <h4 class="mt-5">{{ categorie.nom }} ( {{productsInCategorie(categorie.id).length}} )</h4>
          </v-row>
        </v-container>
      
        <div class="container-custom">
          <v-col
            v-for="produit in productsInCategorie(categorie.id)"
            :key="produit.title"
            style="padding: 3px; width: 200px; height: 200px"
          >
    
            <!-- Carte -->
            <v-card to="/produit" @click.native="setProduitInStore(produit)" elevation="3">
    
              <v-img
                :src="produit.href"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                min-height="197px"
              >
                <v-card-title v-text="produit.nom" style="word-break: normal !important; font-size: large"></v-card-title>
              </v-img>
    
            </v-card>
          </v-col>
        </div>
      
      </template>
    </v-row>
    </v-container>

  </v-container>
</template>

<script>
import { store } from "@/store.js";

export default {
  name: 'produits',
  distributeurs: store.state.distributeurs,
  data() {
    return {
      produits: store.state.produits,
      categories: store.state.categories,
      distributeurs: store.state.distributeurs,
      model: null,
      show: false,
    }
  },
  methods: {
    setProduitInStore(obj){
      
      let obj_formated = {
        variable: "current_produit",
        data: obj,
        
      }
      
      store.commit('setObj', obj_formated);
    },
    productsInCategorie(categorie_id) {
            
      // On récupère les produits dans les catégories
      let produits_in_categorie = this.produits.filter(produit => produit.categorie_id == categorie_id);
            
      // Si un filtre n'est actif et valide.      
      if(!this.$root.filtres[0] == undefined){
        
        // Je retourne simplements les produits de la catégorie.
        return produits_in_categorie;
      }else{
        
        // Sinon je filtre les produits si ils sont bien dans les distributeurs.
        return this.applyFiltre(produits_in_categorie);
        
      }
      
    },
    
    applyFiltre(produits_initial){
      
      // Liste des produits finals.
      let produits_final = [];
      
      // Je récupère les produits dans le distributeur que je filtre.      
      let produits_in_distributeur_filtres = store.state.produit_distributeur[this.$root.filtres.id];
      
      // Si un filtre est actif.
      if(this.$root.filtres.id != undefined){
        
        // Pour touts les produits initiales
        for (let index in produits_initial) {
          
          // Si le produit est dans la liste des produits du distributeurs.
          for (let index_a in produits_in_distributeur_filtres) {
                      
            // Si les ids des produits correspondent.
            if(produits_in_distributeur_filtres[index_a].id == produits_initial[index].id){
              
              let flag = true;
              
              for (let index_p_f in produits_final) {
                
                if(produits_initial[index].id == produits_final[index_p_f].id){
                  flag = false;
                }
                
              }
              
              if(flag){
                // Je l'ajoute a la liste si il n'existe pas encore.
                produits_final.push(produits_initial[index]);
              }
              
            }
            
          }
          
        }
        
      }else{
        
        // Je récupère les produits dans le distributeur que je filtre.      
        produits_in_distributeur_filtres = store.state.produit_distributeur;
        
        // Pour touts les produits initiales
        for (let index in produits_initial) {
                    
          // Si le produit est dans la liste des produits du distributeurs.
          for (let index_a in produits_in_distributeur_filtres) {
            
            for (let index_b in produits_in_distributeur_filtres[index_a]) {
                      
              // Si les ids des produits correspondent.
              if(produits_in_distributeur_filtres[index_a][index_b].id == produits_initial[index].id){
                
                let flag = true;
                
                for (let index_p_f in produits_final) {
                  
                  if(produits_initial[index].id == produits_final[index_p_f].id){
                    flag = false;
                  }
                  
                }
                
                if(flag){
                  // Je l'ajoute a la liste si il n'existe pas encore.
                  produits_final.push(produits_initial[index]);
                }
                
              }
            
            }
            
          }
          
        } 
        
      }
      
      // [DEBUG]
      //console.log("Filtre actif = ", this.$root.filtres.nom + " / " + this.$root.filtres.id);
      //console.log("Nb produits dans la catégorie = ", produits_initial.length);
      //console.log("Nb produits qui correspondent = ", produits_final.length);
     
     // On retourne la liste de produits triés.
      return produits_final;
    },
    
    categorieHasProduits(categorie_id){
            
      let produits_ok = this.produits.filter(produit => produit.categorie_id == categorie_id);
                  
      // Si un filtre n'est actif et valide.      
      if(!this.$root.filtres[0] == undefined){
                        
        // Je retourne simplements les produits de la catégorie.
        return produits_ok.length != 0;
      }else{
        // Sinon je filtre les produits si ils sont bien dans les distributeurs.
        return this.applyFiltre(produits_ok).length != 0;        
      }
      
    },
    distributeursNames(){
      
      let names = [];
      
      for (let index in this.distributeurs) {
         names.push(this.distributeurs[index].nom);
      }
                        
      return names;
    },
  }
}
</script>

<style scoped>
  
.container-custom {
  display: flex;
  overflow-x: auto;
}
.container-custom::-webkit-scrollbar {
  display: none;
}



#create .v-btn--floating {
  position: relative;
}

.v-card__title {
  
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
  line-height: 1.2rem;
  padding: 5px;
  
}

</style>

<template>
  <v-container class="view_tinder" style="padding: 0px">  
    <Tinder></Tinder>
  </v-container>
</template>

<script>
import Tinder from '../components/SwipeableCards.vue';

export default {
  name: 'tinder',
  components: {
    Tinder,
  },
  mounted: function() {
    
    this.$root.header_visible = false;
    this.$root.footer_visible = false;
  }
}
</script>

<style>
</style>

<template>
  <v-container class="view_contact">
      <v-btn
        class="mb-2"
        color="pink"
        to="/"
        dark
      >
        <v-icon
          dark
          left
        >
          fa-arrow-left
        </v-icon>Accueil
      </v-btn>
    
    <!-- Titre -->
    <v-row dense class="align-center space-top">
      <h3 style="padding-left: 5px" class="mt-5">Contact</h3>
    </v-row>
    
    <v-list>
      <template v-for="item in items">
        <v-list-item
          :key="item.text"
          :href="item.prefix + item.value"
          :disabled="loading"
          @click="selected.push(item)"
        >
          <v-list-item-avatar>
            <v-icon
              :disabled="loading"
              v-text="item.icon"
              :color="item.color"
            ></v-icon>
          </v-list-item-avatar>
          <v-list-item-title v-text="item.nom"></v-list-item-title>
              <v-list-item-action>
                <v-icon
                  :disabled="loading"
                  :color="item.color"
                >fas fa-external-link-alt</v-icon>
              </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
    
  </v-container>
</template>

<script>
import { store } from "@/store.js";

export default {
  name: 'contact',
  data: () => ({
    loading: false,
    search: '',
    selected: [],
    items: store.state.contacts,
  }),
}
</script>

<style>
</style>

<template>
  <v-container class="view_distributeurs">
  
    <!-- Titre -->
    <v-row dense class="align-center space-top">
      <h3 style="padding-left: 5px"> {{ $root.messages.nos_distributeur_titre }}</h3>
      
      <v-spacer></v-spacer>
      
      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? 'fa-chevron-up' : 'fa-chevron-down' }}</v-icon>
      </v-btn>
      
      <!-- Texte -->
      <v-expand-transition>
        <div v-show="show">
          
          <v-divider></v-divider>
  
          <v-card-text>
            {{ $root.messages.nos_distributeur_text }}
          </v-card-text>
        </div>
      </v-expand-transition>
      
    </v-row>
          
    <!-- Publications -->
    <v-row          
      dense
    >
      <v-col
        v-for="distributeur in distributeurs"
        :key="distributeur.title"
        cols="6"
        sm="6" 
        md="4" 
        lg="3" 
        xl="2" 
        style="padding: 5px"
      >
        <!-- Carte -->
        <v-card to="/distributeur" @click.native="$root.distributeur_obj = distributeur" style="padding: 0px">

          <v-img
            :src="distributeur.href"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
            <v-card-title v-text="distributeur.nom"></v-card-title>
          </v-img>

        </v-card>
  

      </v-col>
    </v-row>
    
    <!-- Que faisons nous ? -->
    <v-expansion-panels class="space-top-2x mt-3">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <b>{{ $root.messages.cest_quoi_titre }}</b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
      
      <small class="mt-5" style="padding-left: 5px">{{ $root.messages.cest_quoi_text }}</small>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    

    <!-- Titre -->
    <v-row 
      dense 
      v-if="etapes.length > 0"
      class="align-center mt-5"
    >
      <h3 style="padding-left: 5px">{{ $root.messages.comment_ça_marche }}</h3>
    </v-row>
    
    <v-row justify="center" style="padding-right: 10px !important" v-if="etapes.length > 0"> 
      
      <v-timeline
        align-top
        :dense="$vuetify.breakpoint.smAndDown"
      >
        <v-timeline-item
          v-for="(item, i) in etapes"
          :key="i"
          :color="item.color"
          :icon="item.icon"
          fill-dot
        >
          <v-card
            :color="item.color"
            dark
          >
            <v-card-title class="title">
              <b>{{ item.titre }}</b>
            </v-card-title>
            <v-card-text dark class="text--white" style="padding-top: 10px !important">
              <p>{{ item.texte }}</p>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
  
    </v-row>

  </v-container>
</template>

<script>
import { store } from "@/store.js";

export default {
  name: 'distributeurs',
    data() {
      return {
        distributeurs: store.state.distributeurs,
        etapes: store.state.etapes,
        show: false,
      items: [
        {
          color: 'pink',
          icon: 'fa-hand-pointer',
          title: 'Choisir les produits',
          text: 'Vous choissisez le ou vos produits que vous souhaitez parmis une gamme de plus de 250 produits.',
        },
        {
          color: 'pink',
          icon: 'fa-tablet-alt',
          title: 'Faire sa liste',
          text: 'Vous choissisez vos produits sur l\'écran du distributeur.',
        },
        {
          color: 'pink',
          icon: 'fa-money-check',
          title: 'Paiement',
          text: 'Vous payer votre panier sur le distributeur grâce a votre carte bancaire.',
        },
        {
          color: 'pink',
          icon: 'fa-hand-holding',
          title: 'Récupérer les produits',
          text: 'Vous récupérer vos produits dans les casiers indiquer par l\'écran.',
        },
    
      ],
      };
    },
}
</script>

<style scoped>
  
.v-card__title {
  
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
  line-height: 1.2rem;
  padding: 5px;
  word-break: break-word;
  
}
</style>

<template>
  <v-container id="view_distributeur_produit">
      <v-btn
        class="mb-2"
        color="pink"
        to="/distributeurs"
        dark
      >
        <v-icon
          dark
          left
        >
          fa-arrow-left
        </v-icon>{{ $root.distributeur_obj.nom != undefined ? $root.distributeur_obj.nom  : 'Liste des distributeurs' }}
      </v-btn>
    
    <!-- Titre -->
    <v-row dense class="align-center space-top">
      <h3 style="padding-left: 5px">En vente</h3>
      
      <small style="padding-left: 5px">Voici la liste des produit <b>actuellement</b> dans nos distributeurs.</small>
    </v-row>
    
    <div
      v-for="distributeur in distributeurs" :key="distributeur.id" class="mt-5"
    >
    
      <h3> {{ distributeur.nom }}</h3>
                  
      <!-- Publications -->
      <v-row          
        dense
      >
        <v-col
          v-for="produit in produit_distributeur(distributeur.id)"
          :key="produit.title"
          cols="6"
          sm="6" 
          md="4" 
          lg="3" 
          xl="2" 
          style="padding: 5px"
        >
  
          <!-- Carte -->
          <v-card to="/produit" @click.native="$root.produit_obj = produit">
  
            <v-img
              :src="produit.href"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title v-text="produit.nom" style="word-break: normal !important; font-size: large"></v-card-title>
            </v-img>
  
          </v-card>
    
  
        </v-col>
      </v-row>
    </div>
        
  </v-container>
</template>

<script>
  
  import axios from 'axios';
  import { store } from "@/store.js";
  

  export default {
    name: 'Distributeur_produit',
    data() {
      return {
        produits: store.state.produits,
        distributeurs: store.state.distributeurs,
      };
    },
    methods: {
      produit_distributeur: function (distributeur_id) {
        //console.log("store.state.produit_distributeur[distributeur_id]", store.state.produit_distributeur[distributeur_id])
        
        return store.state.produit_distributeur[distributeur_id];
      },
      getProduits: function (distributeur_id) {        
        axios
          .get(this.$root.base_url + "getProduitInDistributeur/"+distributeur_id)
          .then(res => {
            let obj = {
              variable: "produit_distributeur",
              data: res.data,
              sous_id: distributeur_id,
            }
            //store.state.distributeurs = res.data;
            store.commit('setObj', obj);
          })          
      },
    },
    created: function() {
      
      
      for (var i = 0; i < store.state.distributeurs.length; i++) {        
        this.getProduits(store.state.distributeurs.[i].id);
      }

      
    }
  }
</script>

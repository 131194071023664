<template>
  <v-container v-bind:style="styleObject" id="tinder">
  
    <v-btn
    class="mb-2"
    color="pink"
    to="/"
    @click.native="($root.footer_visible = true) && ($root.header_visible = true)"
    dark
  >
    <v-icon
      dark
      left
    >
      fa-arrow-left
    </v-icon>Accueil
  </v-btn>

    <FlipCard :flipped="flipped" v-if="isVisible">
      <template slot="front">
        <div
          v-if="current"
          class="fixed fixed--center"
          style="z-index: 3"
          :class="{ 'transition': isVisible }">
          <Vue2InteractDraggable
            v-if="isVisible"
            :interact-out-of-sight-x-coordinate="100"
            :interact-max-rotation="15"
            :interact-x-threshold="200"
            :interact-y-threshold="200"
            :interact-event-bus-events="interactEventBus"
            interact-block-drag-down
            @draggedRight="emitAndNext('match')"
            @draggedLeft="emitAndNext('reject')"
            @draggedUp="emitAndNext('skip')"
            @draggedBottom="emitAndNext('back')"
            class="rounded-borders card card--one">
            <div style="height: 100%">
              <div class="text-vote-positif text-center" v-if="current.id == 1">
                <h2><span>Vous avez déjà aimé !</span></h2>
              </div>
              <div class="text-vote-negatif text-center" v-if="current.id == -1">
                <h2><span>Vous n'avez pas aimé !</span></h2>
              </div>
              <img
                :src="current.href"
                class="rounded-borders"/>
              <div class="text">
                <h2>{{current.nom}}</h2>
              </div>
            </div>
          </Vue2InteractDraggable>
        </div>
      </template>
      <template slot="back">
      
        <div
          class="fixed fixed--center rounded-borders card card--on"
          style="z-index: 3"
          v-if="isVisible"
        >
  
          <div class="rounded-borders" style="height: 100%; background-color: black">
            <v-card-text class="titre text--white" dark>
              {{ current.description != ' ' ? current.description : 'Pas de description pour ce produit' }}
            </v-card-text>
            <div class="text">
              <h2>{{current.nom}}</h2>
            </div>
          </div>
        </div>
      
      </template>
    </FlipCard>
      
    <div
      v-if="next"
      class="rounded-borders card card--two fixed fixed--center"
      style="z-index: 2">
      <div style="height: 100%">
        <img
          :src="next.href"
          class="rounded-borders"/>
        <div class="text">
            <h2>{{next.nom}}</h2>
          </div>
      </div>
    </div>
    <div
      v-if="index + 2 < produits.length"
      class="rounded-borders card card--three fixed fixed--center"
      style="z-index: 1">
      <div style="height: 100%">
      </div>
    </div>
    
    <div class="footer fixed">
      
      <!-- Boutons -->
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="error"
        @click="reject"
      >
        <v-icon dark>
         fas fa-times
        </v-icon>
      </v-btn>
    

      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="primary"
        @click="toggleCard(current)"
      >
        <v-icon dark>
          fa-question
        </v-icon>
      </v-btn>
      
      <v-btn
        class="mx-2"
        fab
        dark
        x-large
        color="warning"
        @click="back"
        v-if="index > 0"
      >
        <v-icon dark>
         fa-undo-alt
        </v-icon>
      </v-btn>
      
      
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="secondary"
        @click="skip"
      >
        <v-icon dark>
          fa-bookmark
        </v-icon>
      </v-btn>
      
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="success"
        @click="match"
      >
        <v-icon dark>
          fas fa-heart
        </v-icon>
      </v-btn>

    </div>
  </v-container>
</template>
<script>
import { Vue2InteractDraggable, InteractEventBus } from 'vue2-interact'
import FlipCard from './FlipCard.vue'
import { store } from "@/store.js";

const EVENTS = {
  MATCH: 'match',
  SKIP: 'skip',
  REJECT: 'reject'
}

const COLOR = {
  MATCH: 'green',
  REJECT: 'red',
  DEFAULT: 'grey'
}

export default {
  name: 'SwipeableCards',
  components: { Vue2InteractDraggable, FlipCard },
  data() {
    return {
      produits: store.state.produits,
      isVisible: false,
      index: 0,
      flipped: false,
      styleObject: {
        backgroundColor: COLOR.DEFAULT,
        fontSize: '13px',
      },
      interactEventBus: {
        draggedRight: EVENTS.MATCH,
        draggedLeft: EVENTS.REJECT,
        draggedUp: EVENTS.SKIP
      },
    }
  },
  computed: {
    current() {
      return store.state.produits[this.index]
    },
    next() {
      return store.state.produits[this.index + 1]
    }
  },
  mounted: function() {
    
    setTimeout(() => {
      this.isVisible = true
    }, 500)
    
  },
  methods: {
    
    toggleCard () {
      this.flipped = !this.flipped;      
    },
    back(){
      setTimeout(() => {
        this.index--
        this.isVisible = true
      }, 200)
    },
    match() {
      
      //let likeds = store.getters.getLikedProducts;
      //let unlikeds = store.getters.getUnLikedProducts;
      
      //likeds[this.current.id] = this.current.id;
      
      //if()
      
      // SET
/*
      let obj = {
        variable: "liked_produits_ids",
        data: likeds,
      }
*/
      
      //store.commit('setObj', obj);

      
      InteractEventBus.$emit(EVENTS.MATCH)
    },
    reject() {
      this.produits[this.index].like = -1;
      InteractEventBus.$emit(EVENTS.REJECT)
    },
    skip() {
      this.produits[this.index].like = 0;
      InteractEventBus.$emit(EVENTS.SKIP)
    },
    emitAndNext(event) {
    
      if(event == "match"){
        this.styleObject.backgroundColor = COLOR.MATCH;
      }
      
      if(event == "reject"){
        
        this.styleObject.backgroundColor = COLOR.REJECT;
      }
      
      setTimeout(() => this.styleObject.backgroundColor = COLOR.DEFAULT, 1000)

      
      this.$emit(event, this.index)
      setTimeout(() => this.isVisible = false, 200)
      setTimeout(() => {
        this.index++
        this.isVisible = true
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>


.header {
  width: 100%;
  height: 60vh;
  z-index: 0;
  top: 0;
  left: 0;
  color: white;
  text-align: center;
  font-style: italic;
  font-family: 'Engagement', cursive;
  background: #f953c6;
  background: -webkit-linear-gradient(to top, #b91d73, #f953c6);
  background: linear-gradient(to top, #b91d73, #f953c6);
  clip-path: polygon(0 1%, 100% 0%, 100% 76%, 0 89%);
  display: flex;
  justify-content: space-between;
  span {
    display: block;
    font-size: 4rem;
    padding-top: 2rem;
    text-shadow: 1px 1px 1px red;
  }
  i {
    padding: 24px;
  }
}

.footer {
  width: 77vw;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding-bottom: 30px;
  justify-content: space-around;
  align-items: center;
}

.btn {
  position: relative;
  width: 50px;
  height: 50px;
  padding: .2rem;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 6px 6px -3px rgba(0,0,0,0.02), 0 10px 14px 1px rgba(0,0,0,0.02), 0 4px 18px 3px rgba(0,0,0,0.02);
  cursor: pointer;
  transition: all .3s ease;
  user-select: none;
  -webkit-tap-highlight-color:transparent;
  &:active {
    transform: translateY(4px);
  }
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &::before {
      content: '';
    }
  }
  &--like {
    background-color: red;
    padding: .5rem;
    color: white;
    box-shadow: 0 10px 13px -6px rgba(0,0,0,.2), 0 20px 31px 3px rgba(0,0,0,.14), 0 8px 38px 7px rgba(0,0,0,.12);
    i {
      font-size: 32px;
    }
  }
  &--decline {
    color: red;
  }
  &--skip {
    color: green;
  }
}

.flex {
  display: flex;
  &--center {
    align-items: center;
    justify-content: center;
  }
}

.fixed {
  position: fixed;
  &--center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.rounded-borders {
  border-radius: 12px;
}
.card {
  width: 80vw;
  height: 60vh;
  color: white;
  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }
  &--one {
    box-shadow: 0 1px 3px rgba(0,0,0,.2), 0 1px 1px rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
  }
  &--two {
    transform: translate(-48%, -48%);
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2), 0 10px 14px 1px rgba(0,0,0,.14), 0 4px 18px 3px rgba(0,0,0,.12);
  }
  &--three {
    background: rgba(black, .8);
    transform: translate(-46%, -46%);
    box-shadow: 0 10px 13px -6px rgba(0,0,0,.2), 0 20px 31px 3px rgba(0,0,0,.14), 0 8px 38px 7px rgba(0,0,0,.12);
  }
  .text {
    position: absolute;
    bottom: 0;
    width: 100%;
    background:black;
    background:rgba(0,0,0,0.5);
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    text-indent: 20px;
    span {
      font-weight: normal;
    }
  }
  .text-vote-positif {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background-color:green;
    text-indent: 20px;
    span {
      font-weight: normal;
    }
  }
  .text-vote-negatif {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background-color:red;
    text-indent: 20px;
    span {
      font-weight: normal;
    }
  }
}

.transition {
  animation: appear 200ms ease-in;
}

@keyframes appear {
  from {
    transform: translate(-48%, -48%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 600px) {
  .titre {
    position: absolute;
    right: 0;
    color: white;
    width: 100%;
    font-size: 1rem !important;
    line-height: normal !important;
    padding: 1.5rem !important;
  }
}

@media screen and (min-width: 601px) {
  .titre {
    position: absolute;
    right: 0;
    color: white;
    width: 100%;
    font-size: 1.8rem !important;
    line-height: normal !important;
    padding: 4rem !important;
  }
}

p {
  font-family: 'Engagement', cursive;
}

#tinder{
  padding-top: 48px ;
  height: 100vh;
}

</style>
<template>
  <v-container class="view_actualites">
  
    <!-- Titre -->
    <v-row dense class="align-center space-top">
      <h3 style="padding-left: 5px">{{ $root.messages.nos_actualites }}</h3>
      
      <v-spacer></v-spacer>
      
      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? 'fa-chevron-up' : 'fa-chevron-down' }}</v-icon>
      </v-btn>
      
      <!-- Texte -->
      <v-expand-transition>
        <div v-show="show">
          
          <v-divider></v-divider>
  
          <v-card-text>
            {{ $root.messages.nos_actualites_text }}
          </v-card-text>
        </div>
      </v-expand-transition>
      
    </v-row>
    
    <!-- Empty -->
    <emptyBox message_name="Il n'y a pas d'actualités !" :show="$root.isEmpty(actualites)"></emptyBox>
    
    <!-- Actualités -->
    <v-row dense v-if="!$root.isEmpty(actualites)">

      <v-col
        v-for="(actualite, i) in actualites"
        :key="i"
        cols="12"
      >      
      
        <v-card
          class="mx-auto"
          color="primary"
          max-width="400"
          dark
          to="/actualite" @click.native="$root.actualite_obj = actualite"
        >
          <v-card-title>
            <span class="text-h6 font-weight-light" style="word-break: normal !important; font-size: large">{{ actualite.titre }}</span>
          </v-card-title>
      
          <v-card-text class="font-weight-bold" v-html="test(actualite.contenue)">
          </v-card-text>
      
          <v-card-actions>
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3">
                <v-img
                  class="elevation-6"
                  alt=""
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                ></v-img>
              </v-list-item-avatar>
      
              <v-list-item-content>
                <v-list-item-title>{{ actualite.auteur }}</v-list-item-title>
              </v-list-item-content>
      
              <v-row
                align="center"
                justify="end"
              >
                <v-btn
                  text
                  color="white"
                >
                  {{ $root.messages.read }}
                </v-btn>
              </v-row>
            </v-list-item>
          </v-card-actions>
        </v-card>

      </v-col>
    </v-row>
        
  </v-container>
</template>

<script>
import { store } from "@/store.js"
import emptyBox from '@/components/emptyBox.vue'


export default {
  name: 'actualites',
  components: {
    emptyBox,
  },
  data() {
    return {
      actualites: store.state.actualites,
      show: false,
      text_test: "Traduit de l'anglais-Messages est une application SMS, RCS et de messagerie instantanée développée par Google pour son système d'exploitation mobile Android. Une interface web est également disponible. Lancé en 2014, il prend en charge la messagerie RCS depuis 2018, commercialisée sous le nom de « fonctionnalités de chat ». Wikipédia (anglais)",
    }
  },
  methods:{
    test(string){
      
      
      if(string.length > 200){
        string = string.substring(0, 200)+ '...';
      }
      
      return decodeURI(string.replace(/(<([^>]+)>)/ig, ''));
    },
    convertDate(date_string) {
    
    //Code venant d'internet => A commenter !!!!!!!
    function addDigit(number) { return (number < 10) ? '0' + number : number; }
    
    //On créer une date depuis le string.
    let date_obj = new Date(date_string);
    
    return [addDigit(date_obj.getDate()), addDigit(date_obj.getMonth()+1), date_obj.getFullYear()].join('/')
  },
  }
}
</script>

<style>
</style>

<template>
  <v-container class="view_produit" flex>
     
      <v-btn
        class="mb-2"
        color="pink"
        to="/produits"
        dark
      >
        <v-icon
          dark
          left
        >
          fa-arrow-left
        </v-icon>Liste des produits
      </v-btn>
      
      <!-- Image -->
      <v-img
        :src="current_produit.href"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      >

        <!-- Nb personnes -->
        <v-btn
          color="primary"
          class="ma-2 white--text"
          right
        >
          <h3>{{ new Intl.NumberFormat("fr-FR", {style: "currency", currency:"EUR"}).format(current_produit.prix_part)}}</h3>
        </v-btn>
        
        <!-- Prix -->
        <v-btn
          color="secondary"
          class="ma-0 white--text"
        >
          {{ current_produit.nb_parts }}
          <v-icon
            right
            dark
          >
            fa-user
          </v-icon>
        </v-btn>
    
    
      </v-img>

      <!-- Titre -->
      <h3 class="mt-5">{{ current_produit.nom }}</h3>
      
      <!-- Description -->
      <div class="mt-5">
        
        <v-alert
          outlined
          type="info"
          prominent
          border="left"
          icon="fa-flag"
          v-if="current_produit.description == ' '"
        >
          {{ $root.messages.not_description }}
        </v-alert>
        
        <small v-if="current_produit.description != ' '">{{ current_produit.description }}</small>
 
        
      </div>
      
      <v-divider class="mx-4 mt-5 mb-2"></v-divider>
      
      <!-- Informations sur le produit -->
      
      <h3 class="mb-1">{{$root.messages.disponibilite_titre}}</h3>
      
      <v-row justify="center" style="margin: 0;">
  
        <v-progress-circular
          color="success"
          width="3"
          v-if="overlay"
          indeterminate
        ></v-progress-circular>

        <!-- Disponibilité -->
        <v-alert
          outlined
          color="success"
          icon="fa-check-circle"
          text
          v-if="isDisponible && !overlay"
        >
        
          {{ $root.messages.disponibilite }}:
                    
          <v-list-item v-for="distributeur in getNamesOfDistributeursAyantLeProduit()" :key="distributeur.id">
            <v-list-item-content>
              <v-list-item-title class="success--text">- {{ distributeur.nom }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
        </v-alert>
              
      </v-row>
      
      <v-row 
        justify="center" 
      > 
        <!-- Bouton j'y vais plusieurs possibilités -->
        <v-btn
          class="mx-2"
          dark
          color="pink"
          v-if="isDisponible && !overlay && (getNamesOfDistributeursAyantLeProduit()).length > 1"
          @click="dialog = !dialog"
        >
          {{ $root.messages.go_map }}
          <v-icon
            right
            dark
          >
            fas fa-map-marked-alt
          </v-icon>
        </v-btn>
        
      </v-row>
      
      
      <v-row 
        justify="center" 
        v-for="distributeur in getNamesOfDistributeursAyantLeProduit()" 
        :key="distributeur.id"
      > 
        
        <!-- Bouton j'y vais une seule possibilité-->
        <v-btn
          class="mx-2"
          dark
          color="pink"
          v-if="isDisponible && !overlay && (getNamesOfDistributeursAyantLeProduit()).length == 1"
          :href="distributeur.google_map_addr"
        >
          {{ $root.messages.go_map }}
          <v-icon
            right
            dark
          >
            fas fa-map-marked-alt
          </v-icon>
        </v-btn>
        
        <v-alert
          outlined
          color="error"
          icon="fa-times"
          text
          v-if="!isDisponible && !overlay"
        >
          {{ $root.messages.not_disponible }}
  
        </v-alert>
      </v-row>
      <h3 class="mb-1">{{ $root.messages.note_titre }}</h3>
      
      <v-alert
        outlined
        color="error"
        icon="fa-times"
        text
        v-if="!$root.onLine"
      >
        {{ $root.messages.not_vote_internet }}

      </v-alert>
      
      <!-- Note -->
      <v-card
        class="elevation-16 mx-auto"
        max-width="700"
        v-if="!already_voted && !voted && $root.onLine"
      >
        <v-card-title class="text-h5">
          {{ $root.messages.note }}
        </v-card-title>
        <v-card-text>
          {{ $root.messages.note_text }}
          <div class="text-center mt-12">
            <v-rating
              v-model="rating"
              icon="fa-star"
              empty-icon="fa-star"
              full-icon="fa-star"
              half-icon="fa-star-half"
              color="yellow darken-3"
              background-color="grey darken-1"
              hover
              large
            ></v-rating>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="pink"
            text
            @click="addAVote(current_produit.id)"
          >
            {{ $root.messages.send_note }}
          </v-btn>
        </v-card-actions>
      </v-card>
      
      <!-- Already voted -->
      <v-card
        class="elevation-16 mx-auto"
        max-width="700"
        v-if="voted"
      >
        <v-card-title class="text-h5">
          {{$root.messages.my_note }} ({{ rating }})
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <v-rating
              v-model="rating"
              icon="fa-star"
              empty-icon="fa-star"
              full-icon="fa-star"
              half-icon="fa-star-half"
              color="yellow darken-3"
              background-color="grey darken-1"
              hover
              large
              readonly
            ></v-rating>
          </div>
        </v-card-text>
      </v-card>
      
      
      <v-dialog
        v-model="dialog"
        max-width="300"
      >
        <v-card>
          <h2 style="padding: 15px">{{ $root.messages.select_distributeur }}</h2>
          <v-divider></v-divider>
          <v-card-text style="height: 300px;">
            <v-radio-group
              column
            >
              <v-radio
                v-for="distributeur in getNamesOfDistributeursAyantLeProduit()"
                :key="distributeur.id"
                :label="distributeur.nom"
                :value="distributeur"
                on-icon="fa fa-square"
                off-icon="far fa-square"
                @change="distributeur_href = distributeur.google_map_addr"
              ></v-radio>
            </v-radio-group>
  
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions elign="right">
            <v-spacer></v-spacer>
            <v-btn
              color="pink darken-1"
              text
              @click="dialog = false"
              :href="distributeur_href"
            >
              {{ $root.messages.rejoindre }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
 </v-container>
</template>

<script>
import axios from 'axios';
import { store } from "@/store.js";

export default {
  name: 'produit',
    data: () => ({
      overlay: true,
      distributeur_produits: {},
      rating: 0.0,
      voted: false,
      dialog: false,
      distributeur_href: '',
    }),
    computed: {    
       isDisponible: function() {
          return Object.keys(this.distributeur_produits).length !== 0;
       },
       already_voted: function(){
          return store.state.rated_ids[store.state.current_produit.id] != undefined;
       },
       current_produit : function(){
          return store.state.current_produit;       
      },
    },
    methods: {
      convertDate(date_string) {
      
      //Code venant d'internet => A commenter !!!!!!!
      function addDigit(number) { return (number < 10) ? '0' + number : number; }
      
      //On créer une date depuis le string.
      let date_obj = new Date(date_string);
      
      return [addDigit(date_obj.getDate()), addDigit(date_obj.getMonth()+1), date_obj.getFullYear()].join('/')
    },
      getVoteCurrentProduit: function(){
        return store.state.rated_ids[store.state.current_produit.id];
      },
      addAVote: function(produit_id){
        let obj = {
          variable: "rated_ids",
          sous_id: produit_id,
          data: this.rating,
        }       
        
        console.log("sous_id", produit_id);
        //store.state.distributeurs = res.data;
        store.commit('setObj', obj);
        
        this.voted = true;
        
        let hash = "12345";
        
        if(store.state.deviceFCMToken != null || store.state.deviceFCMToken != ""){
          hash = store.state.deviceFCMToken;
        }
        const headers = { 
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          };
        
        // Appel API vers le serveur pour voter.
        axios.get(this.$root.base_url + "voteProduit/" + this.current_produit.id + "/" + hash + "/" +this.rating, headers);

        
      },
       getNamesOfDistributeursAyantLeProduit: function() {
         
         // Liste des distributeurs possèdant le produit de disponible.
         let distributeurs_objs_availables = [];
         
          // Pour les distributeurs.
          for (let index in store.state.distributeurs) {
          
            let distributeur_id = store.state.distributeurs[index].id;

            if(store.state.produit_distributeur[distributeur_id] != undefined){

              // Pour toutes les produits distributeurs de ce distributeur.

              for (let index_p_d in store.state.produit_distributeur[distributeur_id]) {
                
                
                let produit = store.state.produit_distributeur[distributeur_id][index_p_d];
                
                if(produit.id == store.state.current_produit.id){
                  distributeurs_objs_availables.push(store.state.distributeurs[index]);
                  
                }

                
              }

            }

          
          }
         
          return distributeurs_objs_availables;
       },
      getDistributeursProduits: function () {
        
        // Récupération du lien distributeur produits.
        (async() => {
          let apiRes = null;
          try {
            apiRes = await axios.get(this.$root.base_url + "isDisponible/" + this.current_produit.id)
          } catch (err) {
            apiRes = err.response;
          } finally {
            console.log(apiRes); // Could be success or error
            
            // Si data trouvé
            if(apiRes.status == 200){
              this.distributeur_produits = apiRes.data;
            }
            
            this.overlay = false;
            
          }
        })();
      
      }
    },
    mounted: function() {
      
      setTimeout(this.getDistributeursProduits, 500);
      
      this.rating = this.getVoteCurrentProduit();
      
      if(this.rating != undefined){
        this.voted = true;
      }
      
    }
}
</script>

<style>
</style>

<template>
  <v-container class="view_actualite">
  
    <v-btn
      class="mb-2"
      color="pink"
      to="/actualites"
      dark
    >
      <v-icon
        dark
        left
      >
        fa-arrow-left
      </v-icon>Liste des actualités
    </v-btn>
      
    <!-- Image -->
    <v-img
      :src="$root.actualite_obj.href"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="180px">
      <v-chip
        color="secondary"
        style="margin: 5px"
        dark
      >Il y a {{ test($root.actualite_obj.date_creation) }}</v-chip>
    </v-img>
    
    <v-expansion-panels class="space-top-2x mt-3">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <b>{{ $root.actualite_obj.titre }}</b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          
        <!-- Tags -->
        <v-row dense>
          <v-chip
            v-for="tag in $root.actualite_obj.tags"
            :key="tag"
            class="ma-1"
            color="pink"
            label
            outlined
            dark
          >
            <v-icon left>
              mdi-label
            </v-icon>
            {{ tag }}
          </v-chip>
        </v-row>
    
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
      

    
    <!-- Contenue de l'article -->
    <v-sheet
      fluid
      id="divActualite"
      class="rounded-lg"
      v-html="$root.actualite_obj.contenue"
      style="margin-top: 1rem; padding: 0.8rem"
    >
    </v-sheet>
  </v-container>
</template>

<script>

export default {
  name: 'actualite',
  methods:{
    test(date_old){
            
      const diffInMs   = new Date() - new Date(date_old)
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      
      return Math.round(diffInDays) + "J";
    },
  }
}
</script>

<style>
</style>
